import React from 'react';

function AccessDenied() {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold text-red-600 mb-4">
                Accès refusé
            </h1>
            <p className="text-xl text-gray-700 text-center max-w-md mb-8">
                Désolé, vous n'avez pas les droits nécessaires pour accéder à cette page.
            </p>
        </div>
    );
}

export default AccessDenied;