import React from 'react';
import { FaBriefcase, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { RxCross1 } from "react-icons/rx";

const OffreModal = ({ offre, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div className="bg-white rounded-lg w-full max-w-4xl shadow-lg overflow-hidden">
                <div className="p-4 sm:p-6">
                    <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg sm:text-xl font-bold text-gray-800">{offre.intitule}</h3>
                        <button
                            className="text-gray-500 hover:text-red-700 focus:outline-none p-2"
                            onClick={onClose}
                            aria-label="Fermer"
                        >
                            <RxCross1 size={20} />
                        </button>
                    </div>
                    <p className="text-base sm:text-lg text-gray-600 mb-2">{offre.entreprise.nom || "Entreprise non disponible"}</p>
                    <div className="flex flex-col sm:flex-row mb-4">
                        <div className="w-full sm:w-2/3 mb-4 sm:mb-0 sm:pr-4">
                            <div className="max-h-60 sm:max-h-96 overflow-y-auto">
                                <div className="text-gray-700 text-sm sm:text-base">
                                    <div dangerouslySetInnerHTML={{
                                        __html: offre.description
                                            .replace(/;/g, '')
                                            .replace(/\. /g, '.<br/><br/>')
                                            .replace(/\n/g, '<br/>')
                                    }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/3">
                            <div className="flex flex-col space-y-3 mb-4">
                                <InfoItem icon={<FaBriefcase />} text={offre.typeContratLibelle || "Non défini"} />
                                <InfoItem icon={<FaMapMarkerAlt />} text={offre.lieuTravail.libelle.split(" - ").reverse().join(" (")}/>
                                <InfoItem icon={<FaClock />} text={offre.dureeTravailLibelle || "Durée de travail non disponible"} />
                            </div>

                            <div className="bg-gray-100 p-4 rounded">
                                <h4 className="font-semibold mb-2 text-blue-500">Salaire brut :</h4>
                                <p className="text-sm">
                                    {offre.salaire?.libelle || offre.salaire?.commentaire || ''}
                                    {(offre.salaire?.libelle || offre.salaire?.commentaire) && offre.salaire?.complement1 ? ' + ' : ''}
                                    {offre.salaire?.complement1 || ''}
                                    {!offre.salaire?.libelle && !offre.salaire?.commentaire && !offre.salaire?.complement1 ? 'Non défini' : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <p className="text-md font-semibold mb-2">Expérience</p>
                        <span className="text-blue-500 text-sm border border-blue-500 rounded-md px-2 py-1 inline-block">
                            {offre.experienceLibelle}
                        </span>
                    </div>
                </div>
                <div className="flex justify-end space-x-4 p-4 bg-gray-50">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 rounded-lg bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none"
                    >
                        Fermer
                    </button>
                    <button
                        onClick={() => window.open(offre.origineOffre?.urlOrigine, '_blank')}
                        className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none"
                    >
                        Postuler
                    </button>
                </div>
            </div>
        </div>
    );
};

const InfoItem = ({ icon, text }) => (
    <div className="flex items-center">
        <span className="text-blue-500 mr-2">{React.cloneElement(icon, { size: 16 })}</span>
        <span className="text-blue-500 text-sm">{text}</span>
    </div>
);

export default OffreModal;