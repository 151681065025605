import { useQueries } from "@tanstack/react-query";

const useJobDetails = (favoriteIds) => {
    const queries = useQueries({
        queries: favoriteIds.map(offerId => ({
            queryKey: ['jobDetail', offerId],
            queryFn: () => fetch(`https://test-formation.youschool.fr/external-api/job/${offerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ offerId })
            }).then(res => res.json()),
            staleTime: Infinity,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }))
    });

    const isLoading = queries.some(query => query.isLoading);
    const isError = queries.some(query => query.isError);
    const isSuccess = queries.every(query => query.isSuccess);

    return {queries, isLoading, isError, isSuccess};
};

export default useJobDetails;