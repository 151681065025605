import React, { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Offre from './Offre';
import useFavorites from '../Hook/useFavorites';
import useJobDetails from '../Hook/useJobDetails';
import { FaRegStar } from 'react-icons/fa';
import { IoBriefcaseOutline, IoExitOutline } from "react-icons/io5";

const Favoris = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { favorites, favorite_isSuccess, favorites_isError, favorites_isLoading } = useFavorites();
    const favoriteIds = favorites?.data?.map(fav => fav.offre_id) || [];
    const { queries, isLoading: jobDetailsLoading, isError: jobDetailsError } = useJobDetails(favoriteIds);

    const jobDetails = useMemo(() => {
        return queries.reduce((acc, query) => {
            if (query.data) {
                acc[query.data.id] = query.data;
            }
            return acc;
        }, {});
    }, [queries]);

    if (favorites_isLoading || jobDetailsLoading) return <div>Chargement...</div>;
    if (favorites_isError || jobDetailsError) return <div>Erreur lors du chargement des données</div>;

    return (
        <div className="flex flex-col md:flex-row bg-slate-100 min-h-screen md:overflow-hidden overflow-y-auto">
            <aside className="md:fixed md:top-0 md:left-0 md:h-screen bg-white p-5 shadow-md w-full md:w-[200px] flex flex-col">
                <img
                    src="/image/YouJobs-logo.png"
                    alt="YouJobs"
                    className="w-full max-w-[200px] h-auto mb-10 self-center"
                    style={{width: '200px', height: 'auto'}}
                />
                <nav className="space-y-4 flex-grow">
                    <Link to={`/accueil${location.search}`}
                          className={`flex items-center text-gray-700 hover:text-blue-700 hover:bg-blue-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap ${
                              location.pathname === '/accueil' ? 'border-l-4 border-l-blue-600 bg-blue-50 text-blue-700' : 'hover:border-l-4 hover:border-l-blue-600'
                          }`}>
                        <IoBriefcaseOutline className="mr-3 text-lg flex-shrink-0"/>
                        <span className="font-semibold">Offres d'emploi</span>
                    </Link>
                    <Link
                        to={`/favoris${location.search}`}
                        className={`flex items-center text-gray-700 hover:text-blue-700 hover:bg-blue-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap ${
                            location.pathname === '/favoris' ? 'border-l-4 border-l-blue-600 bg-blue-50 text-blue-700' : 'hover:border-l-4 hover:border-l-blue-600'
                        }`}
                    >
                        <FaRegStar className="mr-3 text-lg flex-shrink-0"/>
                        <span className="font-semibold">Favoris ({favorite_isSuccess && favorites && Array.isArray(favorites.data) ? favorites.data.length : 0})</span>
                    </Link>
                </nav>
                <Link
                    to="https://formation.youschool.fr/portail-client/accueil"
                    className="flex items-center mt-14 text-gray-700 hover:text-red-500 hover:bg-red-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap hover:border-l-4 hover:border-l-red-600"
                    rel="noopener noreferrer"
                >
                    <IoExitOutline className="mr-3 text-lg flex-shrink-0"/>
                    <span className="font-semibold">Déconnexion</span>
                </Link>
            </aside>
            <main className="flex-1 p-4 md:p-8 md:ml-[200px] md:overflow-y-auto">
                <h2 className="text-2xl font-bold mb-6">Mes Favoris</h2>
                {!favorites?.data || favorites.data.length === 0 ? (
                    <p>Vous n'avez pas encore d'offres favorites.</p>
                ) : (
                    <div className="space-y-4">
                        {favorites.data.map(favori => {
                            const offreDetails = jobDetails[favori.offre_id];
                            if (!offreDetails) {
                                return (
                                    <div key={favori.offre_id}>
                                        {favori.offre_id && !favori.offreDetails ? (
                                            "L'offre n'existe plus"
                                        ) : (
                                            `Chargement des détails pour l'offre ${favori.offre_id}...`
                                        )}
                                    </div>
                                );
                            }
                            return (
                                <Offre
                                    key={offreDetails.id}
                                    offre={offreDetails}
                                    isFavorite={true}
                                />
                            );
                        })}
                    </div>
                )}
            </main>
        </div>
    );
};

export default Favoris;