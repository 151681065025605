import { createContext} from 'react';

export const AuthContext = createContext(false);

export default AuthContext;

/*export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [sessionActive, setSessionActive] = useState(false);

    const checkAuthentication = useCallback(async () => {
        try {
            console.log('Checking authentication...');

            const response = await fetch('/api/verify-token', {
                method: 'GET',
            });
            const data = await response.json();
            console.log('Auth check response:', data);

            if (response.ok && data.isValid) {
                setIsAuthenticated(true);
                setUser(data.user);
                setToken(data.token);
                localStorage.setItem('auth_token', data.token);
                localStorage.setItem('session_active', 'true');
                setSessionActive(true);
                return true;
            } else {
                setIsAuthenticated(false);
                setUser(null);
                setToken(null);
                localStorage.removeItem('auth_token');
                localStorage.removeItem('session_active');
                setSessionActive(false);
                return false;
            }
        } catch (error) {
            console.error('Erreur lors de la vérification de l\'authentification:', error);
            setIsAuthenticated(false);
            setUser(null);
            setToken(null);
            sessionStorage.removeItem('session_active');
            setSessionActive(false);
            return false;
        } finally {
            setIsLoading(false);
            setIsRedirecting(false);
            sessionStorage.removeItem('is_redirecting');
        }
    }, []);

    useEffect(() => {
        console.log('AuthProvider mounted');
        const isRedirecting = localStorage.getItem('is_redirecting') === 'true';
        setIsRedirecting(isRedirecting);
        const session = localStorage.getItem('session_active');
        if (session === 'true') {
            setSessionActive(true);
            checkAuthentication();
        } else {
            setSessionActive(false);
            if (!isRedirecting) {
                checkAuthentication();
            }
        }
    }, [checkAuthentication]);

    const value = {
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        token,
        setToken,
        checkAuthentication,
        isLoading,
        isRedirecting,
        sessionActive
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};*/
